<template>
  <div class="container">
    <div>
      <div class="row mb-2">

        <div class="col-md-12">
          <div class="text-center mt-3 mb-5">
            <h4>订单报告</h4>
            <small>当前显示最近7天内的50个订单报告。当前界面自动刷新获取最新报告，请耐心等待服务器处理报告。</small>
          </div>
        </div>
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" style="width:15%;">任务ID</th>
                <th scope="col" style="width:15%;">开始时间</th>
                <th scope="col" style="width:55%;">处理结果</th>
                <th scope="col" style="width:15%;">下载</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="task in tasks" v-bind:key="task.taskid">
                  <td scope="row">{{task.taskid}}</td> 
                  <td>{{task.create}}</td>
                  <td>
                    <span v-if="task.taskType=='BOM_REPORT'"  class="badge badge-pill bg-primary me-2 text-white">委外生产单</span>{{task.description}}
                    
                    <span class="d-block small mt-2 text-muted" v-if="task.statusCod != 'wait'">
                      <span v-if="task.statusCod == 'part_done'" class="text-danger">部分数据无法处理，</span>
                      更多详情请查看
                      <a v-on:click="download_errors(task)" href="#">
                        处理报告
                      </a>。
                    </span>
                  </td>
                  <td>
                    <a v-if="task.statusCod=='done' || task.statusCod == 'part_done'" class="small px-2"
                      v-on:click="download(task)" href="#">
                      订单报告
                    </a>
                  </td>
              </tr>
              <tr v-if="tasks.length==0" class="align-middle text-center">
                <td colspan="6">
                  <span>没有报告记录</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- row mb-2-->
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import fileDownload from 'js-file-download';

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export default {
  name: 'OemReport',
  components: {
  },
  data () {
    return {
      tasks: [],
      timer: null
    }
  },
  created:function(){
    this.fetch();
  },

  mounted: function(){
    this.timeout();
  },

  beforeUnmount: function(){
    clearTimeout(this.timer)
    this.timer = null
  },

  methods:{
    download_errors: function(task){
      axios.post(
        process.env.VUE_APP_API_URL + '/oem/api_fetch_report_for_errors/',
        JSON.stringify({'taskid':task.taskid}),
        {responseType: 'blob'}
      ).then((res) => {
        fileDownload(res.data, res.headers['download-filename'])
      }).catch((error) =>{
        console.log(error)
      });
    },


    download: function(task){
      axios.post(
        process.env.VUE_APP_API_URL + '/oem/api_fetch_report/',
        JSON.stringify({'taskid':task.taskid}),
        {responseType: 'blob'}
      ).then((res) => {
        fileDownload(res.data, res.headers['download-filename'])
      }).catch((error) =>{
        console.log(error)
      });
    },

    timeout: function() {
      var self = this;
      this.timer = setTimeout(function () {
        // Do Something Here
        // Then recall the parent function to
        // create a recursive loop.
        self.fetch();
        self.timeout();
      }, 10000);
    },

    fetch: function(){
      var self = this;

      axios.get(
        process.env.VUE_APP_API_URL+'/oem/api_get_tasks/'
      ).then((res) => {
        const data = res.data
        if (data && data.status == 'success'){
          self.tasks = data.tasks;
        }else{
          self.description = data.description;
          self.tasks= null;
        }
      }).catch((error) =>{
        console.log(error)
      });
    }
  }
}
</script>

<style>
  a:link {
    text-decoration: none;
  }
  @media print{
      *{display:none;}
      #print-container,#print-container * {display:block;}
  }

  table .feather{
      color:#a1a1a1;
  }
  table svg.active{
      color: #007bff!important;
  }
  tr:last-child{
      border-bottom:1px solid #e1e1e1;
  }
  .d-inline-block{width:100%;}
</style>
