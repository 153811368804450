import { createRouter, createWebHistory } from 'vue-router'

import SuperOemDashboard from '../views/SuperOemDashboard.vue'
import OemCarrierLabels from '@/views/OemCarrierLabels.vue'
import OemConfirmShip from '@/views/OemConfirmShip.vue'
import OemReport from '@/views/OemReport.vue'


const routes = [
  {
    path: '/oem/',
    name: 'SuperOemDashboard',
    component: SuperOemDashboard
  },
  {
    path: '/oem/carrier_labels/',
    name: 'OemCarrierLabels',
    component: OemCarrierLabels
  },
  {
    path: '/oem/confirm_ship/',
    name: 'OemConfirmShip',
    component: OemConfirmShip
  },
  {
    path: '/oem/report/',
    name: 'OemReport',
    component: OemReport
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
