<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4 col-sm-12 col-lg-4">
        <Calendar :first-day-of-week="2" locale="zh" :step="step" :rows="step" :is-expanded="true" :attributes='attrs' :from-page="page_start"/>
      </div>
      <div class="col-12 col-md-8 col-sm-12 col-lg-8">
        <div class="row justify-content-center text-center p-5 border rounded">
          <div class="col-md-5">
            <h1 class="text-gradient text-info sul" v-on:click="sul('unship')">{{stat.unship_cnt}}</h1>
            <h5 class="mt-3">待配送</h5>
            <p class="small">截至今天所有未配送的订单数量</p>
          </div>
          <div class="col-md-5">
            <h1 class="text-gradient text-info sul" v-on:click="sul('shipped')">{{stat.today_shipped_cnt}}</h1>
            <h5 class="mt-3">今日配送</h5>
            <p class="small">今天已确认配送订单数量</p>
          </div>
        </div>
        <div class="row justify-content-center text-center p-5 border rounded mt-2">
          <div class="col-md-3">
            <h1 class="text-gradient text-info sul" v-on:click="sul('expired_status')">{{stat.expired_order_cnt}}</h1>
            <h5 class="mt-3">逾期订单</h5>
            <p class="small">所有配送超时订单数量</p>
          </div>
          <div class="col-md-3">
            <h1 class="text-gradient text-info">{{stat.today_print_cnt}}</h1>
            <h5 class="mt-3">今日扫描</h5>
            <p class="small">今天扫面的面单数量</p>
          </div>
          <div class="col-md-3">
            <h1 class="text-gradient text-info sul" v-on:click="sul('priority')">{{stat.priority_ord_cnt}}</h1>
            <h5 class="mt-3">加急订单</h5>
            <p class="small">所有待配送的加急订单数量</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Calendar } from 'v-calendar';

import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export default {
  name: 'SuperOemDashboard',
  components: {
    Calendar
  },
  data () {
    return {
      attrs: [],
      step: 2,
      page_start:{month:5, year:2021},
      stat: {}
    }
  },

  mounted: function(){
    var self = this;

    axios.get(
      process.env.VUE_APP_API_URL+'/oem/api/get_statistic/'
    ).then((res) => {
      const data = res.data
      if (data && data.status == 'success'){
        self.attrs = data.attrs
        self.page_start = data.page_start
        self.step = data.step
        self.stat = data.stat
      }
    }).catch((error) =>{
      console.log(error)
    });
  },

  methods:{
    sul: function(t){
      window.location.href= '/oem/sul/' + t + '/';
    }
  }


}
</script>


<style>
.sul{
  cursor: pointer;
}
</style>