<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="请输入条形码" aria-label="barcode" aria-describedby="basic-addon2" v-model="barcode" v-on:keyup.enter="scan_enter">
        </div>
      </div>

      <div class="col-md-12" v-if="error_description.length>0">
          <div class="alert alert-warning" role="alert">
            {{error_description}}
            <span v-if="forward_orderid">点击<a :href="'/oem/wait_manufacturing/?wd='+forward_orderid" target="_blank">{{ forward_orderid}}</a></span>
          </div>
      </div>
      
      <div class="col-md-12 mb-3" v-if="orders.length > 0">
          <div class="btn-group pe-3" role="group" aria-label="Carrier Venders">
            <button type="button" :class="{'btn btn-primary': true}"
              @click="switch_label(label.id)"
              v-for="label in ship_catalog.slice(0, 1)" v-bind:key="label.id" >
                {{ label.name}}
                <span class="badge badge-light">{{ orders.length }}</span>
            </button>
            <div v-for="label in ship_catalog" v-bind:key="label.id">
              <button type="button" :class="{'btn btn-primary': true}"
                @click="switch_label(label.id)" v-if="label.cnt>0">
                {{ label.name}}
                <span class="badge badge-light">{{ label.cnt}}</span>
              </button>
            </div>
          </div>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-primary" v-if="current_label !== 'all' && current_label !== 'SELF'"
              @click="download_labels">批量下载标签</button>
            <!--
            <button type="button" class="btn btn-outline-primary" v-if="current_label !== 'all' && current_label !== 'DHL'"
              @click="confirm_print_labels">批量确认打印</button>
            -->
          </div>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-success" @click="confirm_shipped"
              v-if="current_label !== 'all'">批量确认发货</button>
            <button type="button" class="btn btn-outline-success" @click="undo_confirm"
              v-if="current_label !== 'all'">批量撤回发货确认</button>
        </div>
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-outline-primary" @click="clear_orders">清除所以记录</button>
      </div>
      </div>

      <div class="col-md-12 results" v-for="order in orders" v-show="order.show" v-bind:key="order.orderid">
          <table class="table table-hover table-bordered">
            <thead>
              <tr>
                <th scope="col">订单详情</th>
                <th scope="col">产品</th>
                <th scope="col">编码</th>
                <th scope="col">数量</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(it, index) in order.items" v-bind:key="index">
                <td scope="row" :rowspan="order.items.length" v-if="index === 0">
                  <span class="d-block">
                    条形码：{{ order.orderidcode}}
                  </span>
                  <span class="d-block">订单编号：{{ order.orderid}}</span>
                  <span class="d-block">订单确认时间：{{ order.do_manufacturing_dt}}</span>
                  <hr>
                  <span class="d-block">承运：{{ order.readable_carrier}}</span>
                  <span class="d-block">追踪号：{{ order.carrierid}}</span>
                  <span class="d-block text-danger">上次标签打印日期：{{ order.printLabelDate}}</span>
                  <span class="d-block text-danger">面签有效期：{{ order.label_dt}}</span>
                  <hr>
                  <button type="button" class="btn btn-success" @click="download_fnsku_labels(order.orderid)" v-if="order.readable_carrier=='SELF'">下载所有商品标签</button>
                  <hr>
                  <button type="button" class="btn btn-outline-primary" @click="remove_item(order.orderid)">移除扫描</button>
                  <button type="button" class="btn btn-outline-primary" @click="cancel_confirm(order)" v-if="order.printLabelDate">撤回打印确认</button>
                </td>
                <td><img :src="it.url" style="max-width: 72PX; max-height: 72PX;" /></td>
                <td>
                  <span class="d-block">{{ it.itemid}}</span>
                  <span class="d-block text-muted small">{{ it.sku}}</span>
                  <hr>
                  <span class="d-block text-muted small"><strong>商品条形码: </strong>{{it.fnsku}}</span>
                </td>
                <td>{{ it.quantity}}</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div><!--row-->
  </div>
</template>

<script>

import axios from 'axios';
import fileDownload from 'js-file-download';

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFToken";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  name: 'OemCarrierLabels',
  data () {
    return {
      barcode: '',
      orders: [],
      forward_orderid: '',
      error_description: '',
      ship_catalog: [],
      current_label: 'all',
    }
  },
  created:function(){
    this.update_labels();
  },

  computed: {
  },

  methods:{
    undo_confirm: function(){
      var self = this;
      this.error_description = '';
      var ids = [];
      $.each(self.orders, function(idx, val){
        if (val.shipmethod == self.current_label && val.show == true){
          ids.push(val.orderid);
        }
      })

      if (ids.length == 0) { return;}

      axios.post(
        process.env.VUE_APP_API_URL+'/oem/api/undo_confirm/',
        JSON.stringify({'orders': ids})
      ).then((res) => {
        const data = res.data
        if (data && data.status == 'success'){
          self.error_description = data.description;
          $.each(self.orders, function(idx, val){
            val.shipDate = '';
            val.orderstatus = '确认生产'
          });
        } else {
          self.description = data.description;
        }
      }).catch((error) =>{
        console.log(error)
      });
    },

    confirm_shipped: function(){
      var self = this;
      this.error_description = '';
      var ids = [];
      $.each(self.orders, function(idx, val){
        if (val.shipmethod == self.current_label && val.show == true){
          ids.push(val.orderid);
        }
      })

      if (ids.length == 0) { return;}

      axios.post(
        process.env.VUE_APP_API_URL+'/oem/api/confirm_shipped/',
        JSON.stringify({'orders': ids})
      ).then((res) => {
        const data = res.data
        if (data && data['status']=='success') {
          $.each(self.orders, function(idx, val){
            val.shipDate = data.dt;
            val.orderstatus = '已发货';
          });
          self.error_description = data.description;
        }
      }).catch((error) =>{
        console.log(error)
      });
    },

    update_count: function(catalog, opt){
      $.each(this.ship_catalog, function(idx, val){
        if (val.id === catalog) {
          if (opt == 'add') {
            val.cnt += 1;
          } else {
            val.cnt -= 1;
          }
        }
      })
    },

    update_labels: function(){
      var labels = [
        {id: 'all', name: '全部', sel: true, show: true, cnt: 0},
        {id: 'VTSX', name: '老梁-4PX-QC', sel: false, show: true, cnt: 0},
        {id: 'DISIFANG_OH', name: '递四方OH/HK', sel: false, show: true, cnt: 0},
        {id: 'DISIFANG_QC', name: '递四方QC', sel: false, show: true, cnt: 0},
        {id: 'DISIFANG_PX', name: '递四方PX', sel: false, show: true, cnt: 0},
        {id: 'EUB', name: 'E邮宝', sel: false, show: true, cnt: 0},
        {id: 'EC', name: '老梁-广州-E邮宝', sel: false, show: true, cnt: 0},
        {id: 'ETK', name: 'E特快', sel: false, show: true, cnt: 0},
        {id: 'DHL', name: 'DHL', sel: false, show: true, cnt: 0},
        {id: 'YANWEN_EXPRESS_USPS_484', name: '燕文专线', sel: false, show: true, cnt: 0},
        {id: 'YUNINGWAREHOUSE_CHANNEL', name: '宇宁海外仓', sel: false, show: true, cnt: 0},
        {id: 'DIANYOUBAO_USPSZTZX', name: '电邮宝', sel: false, show: true, cnt: 0},
        {id: 'SELF', name: '发仓', sel: false, show: true, cnt: 0},
      ];
      this.ship_catalog = labels;
    },

    switch_label: function(new_label){
      this.current_label = new_label;
      var self = this;
      $.each(this.ship_catalog, function(idx, val){
        if (val.id == new_label) {
          val.sel = true;
        } else {
          val.sel = false;
        }
      })

      $.each(this.orders, function(idx, val){
        if (val.shipmethod == self.current_label || self.current_label === 'all') {
          val.show = true;
        } else {
          val.show = false;
        }
      })
    },

    cancel_confirm: function(orderinfo){
      var self = this;
      this.error_description = '';

      axios.post(
        process.env.VUE_APP_API_URL + '/oem/api/cancel_back_to_confirm_manufacturing/'+orderinfo.orderid+'/'
      ).then((res) => {
        const data = res.data

        if(data.status=='success'){
          orderinfo.orderstatus = '确认发货';
          orderinfo.printLabelDate = '';
        } else{
          self.description = data.description;
        }
      }).catch((error) =>{
        console.log(error)
      });
    },

    confirm_print_labels: function(){
      var self = this;
      this.error_description = '';
      var ids = [];
      $.each(self.orders, function(idx, val){
        if (val.shipmethod == self.current_label && val.show == true){
          ids.push(val.orderid);
        }
      })

      if (ids.length == 0) { return;}

      axios.post(
        process.env.VUE_APP_API_URL + '/oem/api/confirm_print_labels/',
        JSON.stringify({'orders': ids})
      ).then((res) => {
        const data = res.data

        if (data && data.status == 'success'){
          self.error_description = data.description;
          $.each(self.orders, function(idx, val){
            val.printLabelDate = data.dt;
          });
        } else {
          self.description = data.description;
        }
      }).catch((error) =>{
        console.log(error)
      });
    },

    remove_item: function(id){
      var remove_item = undefined;
      var self = this;
      $.each(this.orders, function(idx, val){
        if (val.orderid == id) {
          remove_item = idx;
          self.update_count(val.shipmethod, 'remove');
        }
      })

      if (remove_item!== undefined){
        this.orders.splice(remove_item, 1);
      }
    },

    download_labels: function(){
      var self = this;
      this.error_description = '';
      var ids = [];
      $.each(self.orders, function(idx, val){
        if (val.shipmethod == self.current_label && val.show == true){
          ids.push(val.orderid);
        }
      })

      if (ids.length == 0) { return;}

      axios.post(
        process.env.VUE_APP_API_URL + '/oem/api/fetch_eub_labels/',
        {'orders': ids}, {responseType: 'blob'}
      ).then((res) => {
        fileDownload(res.data, res.headers['download-filename'])
      }).catch((error) =>{
        console.log(error)
      });
    },

    clear_orders:function(){
      this.orders = [];
      this.error_description = '';
      this.current_label = 'all';
      $.each(this.ship_catalog, function(idx, val){
        val.cnt = 0;
        if (val.id === 'all') {
          val.sel =true;
        } else {
          val.sel = false;
        }
      })
    },


    download_fnsku_labels: function(orderid){
      axios.post(process.env.VUE_APP_API_URL + '/oem/api/fetch_fnsku_labels/', {"orderid": orderid}, {responseType: 'blob'})
      .then((res)=>{
        fileDownload(res.data, res.headers['download-filename'])
      }).catch((error) =>{
        console.log(error)
      });
    },

    scan_enter: function(){
      let self = this;
      this.error_description = '';

      axios.post(
        process.env.VUE_APP_API_URL+'/oem/api/get_order_by_code/',
        JSON.stringify({'code': self.barcode})
      ).then((res) => {
        const data = res.data
        if (data && data.status == 'success'){
          var hit = false;
          var hit_orderid = '';
          $.each(self.orders, function(idx, val){
            if (val.orderid == data.order.orderid){
              hit = true;
              hit_orderid = val.orderid;
              return;
            }
          })

          if (hit){
            self.error_description = '重复扫描订单 ' + hit_orderid;
          } else {
            self.orders.unshift(data.order);
            self.current_label = data.order.shipmethod;
            self.switch_label(self.current_label);
            self.update_count(data.order.shipmethod, 'add');
          }

          self.barcode = '';
        }
        else {
          self.error_description = data.description;
          self.forward_orderid = data.orderid;
          console.log(data)
        }
      }).catch((error) =>{
        if (error.response) {
          if (error.response.status == 403){
            self.error_description = '无权访问'
          }

          if (error.response.status == 500) {
            self.error_description = ''
          }
        }
        console.log(error)
      });
    }
  },
}
</script>

<style>
</style>
