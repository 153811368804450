<template>
  <div class="container">
    <div class="col-md-12">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="请输入条形码" aria-label="barcode" aria-describedby="basic-addon2" v-model="barcode" v-on:keyup.enter="scan_enter">
      </div>
    </div>

    <div class="col-md-12" v-if="error_description.length>0">
      <div class="alert alert-warning" role="alert">{{error_description}}</div>
    </div>
      
    <div class="col-md-12 mb-3" v-if="orders.length > 0">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-outline-primary" @click="confirm_shipped">批量确认发货</button>
        <button type="button" class="btn btn-outline-primary" @click="undo_confirm">批量撤回发货确认</button>
        <button type="button" class="btn btn-outline-primary" @click="clear_orders">清除扫描记录</button>
      </div>
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-outline-primary disabled">当前已扫描订单 {{orders.length}} 单</button>
      </div>
    </div>

      <div class="col-md-12 results" v-for="order in orders" v-bind:key="order.orderid">
        <table class="table table-hover table-bordered">
          <thead>
            <tr>
              <th scope="col">订单详情</th>
              <th scope="col">产品</th>
              <th scope="col">编码</th>
              <th scope="col">数量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(it, index) in order.items" v-bind:key="index">
              <td scope="row" :rowspan="order.items.length" v-if="index === 0">
                  <span class="d-block">
                      <span class="p-1">条形码：{{order.orderidcode}}</span>
                  </span>
                  <span class="d-block">订单编号：{{order.orderid}}</span>
                  <span class="d-block">订单确认时间：{{order.do_manufacturing_dt}}</span>
                  <span class="d-block text-success">订单状态：{{order.orderstatus}}</span>
                  <span class="d-block text-success" v-if="order.shipDate">发货日期：{{order.shipDate}}</span>
                  <hr>
                  <span class="d-block">承运：{{order.readable_carrier}}</span>
                  <span class="d-block">追踪号：{{order.carrierid}}</span>
                  <span class="d-block text-danger">上次标签打印日期：{{order.printLabelDate}}</span>
                  <span class="d-block text-danger">面签有效期：{{order.label_dt}}</span>
                  <hr>
                  <button type="button" class="btn btn-outline-primary" @click="remove_item(order.orderid)">移除扫描</button>
                  <button type="button" class="btn btn-outline-primary" @click="cancel_ship(order)" v-if="order.orderstatus == '已发货'">撤销确认发货</button>
              </td>
              <td><img :src="it.url" style="max-width: 72PX; max-height: 72PX;" /></td>
              <td>
                <span class="d-block">{{it.itemid}}</span>
                <span class="d-block text-muted small">{{it.sku}}</span>
              </td>
              <td>{{it.quantity}}</td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>
</template>

<script>

import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFToken";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  name: 'OemConfirmShip',
  components: {
  },
  data () {
    return {
      barcode: '',
      orders: [],
      error_description: ''
    }
  },

  methods:{
    cancel_ship: function(orderinfo){
      let self = this;
      this.error_description = '';

      axios.post(
        process.env.VUE_APP_API_URL+'/oem/api/undo_confirm/',
        JSON.stringify({'orders': [orderinfo.orderid]})
      ).then((res) => {
        const data = res.data
        if(data.status=='success'){
          orderinfo.orderstatus = '确认发货';
          orderinfo.shipDate = '';
        } else{
          self.description = data.description;
        }
      }).catch((error) =>{
        console.log(error)
      });
    },

    undo_confirm: function(){
      var self = this;
      this.error_description = '';
      var ids = [];
      $.each(self.orders, function(idx, val){
        ids.push(val.orderid);
      })

      axios.post(
        process.env.VUE_APP_API_URL+'/oem/api/undo_confirm/',
        JSON.stringify({'orders': ids})
      ).then((res) => {
        const data = res.data
        if (data && data.status == 'success'){
          self.error_description = data.description;
          $.each(self.orders, function(idx, val){
            val.shipDate = '';
            val.orderstatus = '确认生产'
          });
        } else {
          self.description = data.description;
        }
      }).catch((error) =>{
        console.log(error)
      });
    },

    remove_item: function(id){
      var remove_item = undefined;
      $.each(this.orders, function(idx, val){
        if (val.orderid == id) {
          remove_item = idx;
        }
      })

      if (remove_item!== undefined){
        this.orders.splice(remove_item, 1);
      }
    },

    confirm_shipped: function(){
      var self = this;
      this.error_description = '';
      var ids = [];
      $.each(self.orders, function(idx, val){
        ids.push(val.orderid);
      })

      axios.post(
        process.env.VUE_APP_API_URL+'/oem/api/confirm_shipped/',
        JSON.stringify({'orders': ids})
      ).then((res) => {
        const data = res.data
        if (data && data['status']=='success') {
          $.each(self.orders, function(idx, val){
            val.shipDate = data.dt;
            val.orderstatus = '已发货';
          });
          self.error_description = data.description;
        }
      }).catch((error) =>{
        console.log(error)
      });
    },

    clear_orders:function(){
      this.orders = [];
      this.error_description = '';
    },

    scan_enter: function(){
      var self = this;
      this.error_description = '';

      axios.post(
        process.env.VUE_APP_API_URL+'/oem/api/get_order_by_barcode/',
        JSON.stringify({'code': self.barcode})
      ).then((res) => {
        const data = res.data
        if (data && data.status == 'success'){
          var hit = false;
          var hit_orderid = '';
          $.each(self.orders, function(idx, val){
            if (val.orderid == data.order.orderid){
              hit = true;
              hit_orderid = val.orderid;
              return;
            }
          })

          if (hit){
            self.error_description = '重复扫描订单 ' + hit_orderid;
          } else {
            self.orders.unshift(data.order)
          }

          self.barcode = '';
        }
        else {
          self.error_description = data.description;
          self.barcode = '';
        }
      }).catch((error) =>{
        console.log(error)
      });
    }
  },

}
</script>
